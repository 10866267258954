.user-basic-upper {
  .wrapper {
    padding: 0;

    .left-side-user-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      padding: 15px 5px;

      .profile_icon {
        border: 2px solid var(--text-color);
        width: 100px;
        height: 100px;
        display: grid;
        margin: 5px auto;
        place-items: center;
        padding: 20px 20px;
        border-radius: 50%;

        svg {
          font-size: 40px;
          color: var(--text-color);
        }
      }

      .user-data-name {
        font-size: 16px;
        color: var(--text-color);
        font-family: 'helvetica-bold';
        font-weight: 700;
        text-align: center;
      }

      .user-data-type {
        font-size: 16px;
        color: var(--secondary-400);
        font-family: 'helvetica-regular';
        font-weight: 400;
        text-align: center;

        .bot-icon {
          margin-left: 7px;
          color: #ce2929;
        }
      }
    }

    .right-side-user-data {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px 5px 0;

      .user-basic-data {
        padding: 5px 15px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 7px;
        color: var(--secondary-600);
        font-family: 'helvetica-regular';
        font-weight: 400;
        text-align: left;
        font-size: 15px;

        svg {
          margin-right: 8px;
        }
      }

      .user-details-button-group {
        vertical-align: bottom;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 15px;

        button {
          background-color: transparent;
          color: var(--text-color);
          font-size: 14px;
          width: 100%;
          border: none;
          padding: 8px 5px;
          position: relative;
          font-family: 'helvetica-regular';
          font-weight: 400;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0%;
            height: 4px;
            background: var(--primary-color);
            transition: 0.3s ease-in-out;
          }

          &.userActive::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background: var(--primary-color);
          }
        }
      }
    }
  }
}

.user-basic-lower {
  margin: 25px 0px;
  padding: 0 5px;

  .wrapper-title {
    font-size: 20px;
    font-family: 'helvetica-bold';
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .details-row {
    gap: 20px 0;

    .details-card {
      color: var(--text-color);
      background: var(--boxes-color);
      height: 100%;
      border-radius: 8px;
      box-shadow: 0px 10px 20px rgba(178, 191, 210, 0.25);

      .details-card-title {
        font-size: 16px;
        color: var(--text-color);
        font-family: 'helvetica-bold';
        font-weight: 700;
        padding: 20px;
        border-bottom: 1px solid var(--text-color);
      }

      .details-card-data {
        padding: 20px;

        .details-data-row {
          gap: 20px 0;

          .data-title,
          .data-value {
            display: block;
            padding: 3px 0;
          }

          .data-title {
            font-size: 11px;
            letter-spacing: 1px;
            font-family: 'helvetica-regular';
            font-weight: 400;
            color: var(--secondary-600);
            text-transform: uppercase;
          }

          .data-value {
            font-size: 13.5px;
            font-family: 'helvetica-bold';
            font-weight: 600;
            color: var(--secondary-600);
          }
        }
      }
    }
  }
}

body.dark {
  .user-basic-upper {
    .wrapper {
      box-shadow: 0px 10px 20px rgba(58, 62, 69, 0.389);

      .right-side-user-data {
        .user-basic-data {
          color: var(--secondary-300);
        }
      }
    }
  }

  .user-basic-lower {
    .details-row {
      .details-card {
        color: var(--text-color);
        background: var(--boxes-color);
        box-shadow: 0px 10px 20px rgba(58, 62, 69, 0.389);

        .details-card-title {
          color: var(--text-color);
        }

        .details-card-data {
          .details-data-row {
            .data-title {
              color: var(--secondary-500);
            }

            .data-value {
              color: var(--secondary-300);
            }
          }
        }
      }
    }
  }
}