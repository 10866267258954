html {
  width: 100%;
}

body {
  font-family: $primary-font;
  font-weight: $fw-400;
  font-size: $fs-12;
  color: var(--secondary-100);
  background-color: var(--theme-color);
  font-style: normal;
  letter-spacing: 0;
  word-spacing: 0;
  text-transform: inherit;
  overflow-x: hidden;
}

%heading {
  font-weight: $fw-700;
  line-height: 1.4;
  color: var(--secondary-100);
}

h1 {
  @extend %heading;
  font-size: $fs-28;
}

h2 {
  @extend %heading;
  font-size: $fs-24;
}

h3 {
  @extend %heading;
  font-size: $fs-20;
}

h4 {
  @extend %heading;
  font-size: $fs-18;
}

h5 {
  @extend %heading;
  font-size: $fs-16;
}

h6 {
  @extend %heading;
  font-size: $fs-14;
}

a {
  color: $primary-300;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $primary-300;
  }
}

button,
button:focus {
  outline: none;
}

img {
  max-width: 100%;
  border: 0px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
ol {
  li {
    font-size: $fs-15;
  }
}

.cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.loading {
  z-index: 9999;
  background: #1c2430;
  opacity: 0.3;
  left: 0;
}

.gutter-9 {
  margin-left: -9px;
  margin-right: -9px;

  div[class*='col-'] {
    padding-left: 9px;
    padding-right: 9px;
  }
}

.text-danger {
  color: $red-300 !important;
}

.sticky-column {
  position: sticky;
  top: 85px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

// ::-moz-selection {
//   color: #ffffff;
//   background: #ffffff47;
// }

// ::selection {
//   color: #ffffff;
//   background: #ffffff47;
// }
.inputStar {
  color: var(--error-color) !important;
  margin: 0 3px !important;
  padding: 0 !important;
  font-size: 15px;
}

.option-icon {
  background-color: var(--primary-color);
  width: 35px;
  height: 35px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  cursor: pointer;

  &.big {
    width: 45px;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: white;
    }
  }
}

.slick-dots {
  li {
    width: fit-content;
    height: fit-content;
    margin: 0;

    &:not(:last-child) {
      margin-right: 5px;
    }

    button {
      background-color: var(--gray-100);
      padding: 0;
      border-radius: 100%;
      width: 10px;
      height: 10px;

      &:before {
        display: none;
      }
    }

    &.slick-active {
      button {
        background-color: var(--primary-color);
      }
    }
  }
}

.input-group {
  input:focus {
    border-color: #ced4da;
    box-shadow: none;
  }
}

.date-data-field {
  min-width: 150px;
}

.textWrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.tag-button {
  padding: 6px 10px;
  letter-spacing: 0.5px;
  border-radius: 99px;
  // border: 1px solid transparent;
  text-transform: uppercase !important;
  font-size: 11px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 600;

  &.success {
    background-color: #E8FAF0;
    color: #12A150;
  }

  &.danger {
    background-color: #fee7e7;
    color: #f31212;
  }

  &.info {}

  &.warning {
    background-color: #f9f3b2;
    color: #C4841D !important;
  }
}

body.dark {
  .tag-button {
    &.success {
      background-color: #094723;
      color: #74DFA2;
    }

    &.danger {
      background-color: #8b0f0f;
      color: #ffd7d7;
    }

    &.info {}

    &.warning {
      background-color: #C4841D;
      color: #FEFCE8;
    }
  }
}