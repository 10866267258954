.side-bar {
  position: fixed;
  left: 0;
  height: 100%;
  width: 56px;
  background-color: var(--boxes-color);
  z-index: 999;
  transition: width 0.3s;
  top: 85px;
  height: calc(100% - 100px);
  border-radius: 10px;
  margin-left: 15px;
  box-shadow: 5px 5px 30px 2px rgb(27 34 99 / 12%);

  .menu {
    height: 98%;
    position: relative;
    padding: 20px 13px 0;

    ul {
      list-style-type: none;

      li {
        position: relative;

        &:nth-child(1) a i,
        &:nth-child(1) .list-name i {
          color: #5e72e4;
        }

        &:nth-child(2) a i,
        &:nth-child(2) .list-name i {
          color: #fb6340;
        }

        &:nth-child(3) a i,
        &:nth-child(3) .list-name i {
          color: #2dce89;
        }

        &:nth-child(4) a i,
        &:nth-child(4) .list-name i {
          color: #11cdef;
        }

        &:nth-child(5) a i,
        &:nth-child(5) .list-name i {
          color: #f5365c;
        }

        &:nth-child(6) a i,
        &:nth-child(6) .list-name i {
          color: #36b05a;
        }

        &:nth-child(7) a i,
        &:nth-child(7) .list-name i {
          color: #ff7500;
        }

        &:nth-child(8) a i,
        &:nth-child(8) .list-name i {
          color: #2163ff;
        }

        &:nth-child(9) a i,
        &:nth-child(9) .list-name i {
          color: #7e1bff;
        }

        &+li {
          margin-top: 10px;
        }

        span.toggle-btn-open+ul {
          opacity: 1;
          visibility: visible;
          display: block;
        }

        a,
        .list-name {
          font-size: 14px;
          color: var(--text-color);
          text-transform: capitalize;
          font-weight: 500;
          opacity: 0.9;
          white-space: nowrap;
          display: block;
          padding: 0;
          border-radius: 5px;
          transition: all 0.3s ease-in-out;

          .side-bar-text {
            transform: translateY(2px);
          }

          i {
            font-size: $fs-16;
            font-weight: normal;
            border-radius: 4px;
            display: inline-block;
            transition: all 0.3s;
            height: 29px;
            line-height: 22px;
            width: 30px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
              height: 20px;
            }
          }

          &.active {
            font-weight: 700;
            font-family: 'Helvetica-Bold';
          }

          &:hover,
          &.active {
            background: linear-gradient(125deg, var(--primary-color), var(--primary-color-light));
            border-color: var(--primary-color);
            color: var(--white-color);

            .drop-icon {
              color: var(--text-color);
            }
          }
        }

        .dropdown-menu {
          top: -19px;
          left: 100%;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;
          overflow: auto;
          max-height: 300px;
          padding: 5px;
          border: 0.5px solid transparent;
          box-shadow: 0px 5px 20px 0px rgb(51 51 51 / 28%);
          display: none;

          li {
            // margin-top: 0;
            margin: 5px 0;

            a,
            .list-name {
              padding: 9px 20px !important;
              font-weight: $fw-500;
              text-transform: none;

              &:hover {
                @include media-min(xl) {
                  color: var(--white-color);
                }
              }

              &.active {
                color: var(--white-color);
              }
            }
          }
        }

        .drop-icon {
          font-size: $fs-22;
          position: absolute;
          right: 3px;
          top: 5px;
          cursor: pointer;
          color: var(--secondary-text-color) !important;
          margin: 0;
          z-index: 9;
        }

        @include media-min(xl) {
          &:hover {

            >a,
            >.list-name {
              background-color: var(--primary-color-light);
              border-color: var(--primary-color);
            }
          }
        }
      }
    }
  }

  .open-btn {
    color: var(--text-color);
    font-size: $fs-24;
    // margin-left: 11px;
    position: absolute;
    bottom: 0;
    left: 10px;

    &:hover i {
      color: var(--primary-color);
    }
  }

  // Expanded
  &.expanded {
    width: 250px;

    .open-btn {
      transform: scaleX(-1);
    }

    .menu {
      ul {
        overflow-y: auto;
        height: 90%;

        li {
          cursor: pointer;
          margin-right: 10px;
          margin-left: 10px;

          a,
          .list-name {
            overflow: hidden;
            // text-align: left;
            padding: 5px 10px;
            display: flex;
            align-items: center;

            i {
              margin-right: 5px;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  @include media-max(xl) {
    .menu {
      ul {
        li {
          .dropdown-menu {
            position: static;
            opacity: 1;
            visibility: visible;
            display: none;
          }

          &.open {
            >.dropdown-menu {
              display: block;
            }
          }
        }
      }
    }
  }
}

.sidebar-dropdown {
  position: relative !important;
  top: 100% !important;
  left: 0 !important;
  width: 100%;
  transition: 0.3s;
}

.sidebar-dropdown.active {
  opacity: 1 !important;
  visibility: visible !important;
  transition: 0.3s;
}

body.dark {
  .side-bar {
    box-shadow: 5px 5px 30px 2px rgb(27 34 99 / 28%);

    .menu {
      ul {
        li {
          .dropdown-menu {
            background: var(--primary-color-light);
            box-shadow: 0px 5px 20px 0px rgb(219 219 219 / 14%);
          }

          &:nth-child(1) a i,
          &:nth-child(1) .list-name i {
            color: #677eff;
          }

          &:nth-child(2) a i,
          &:nth-child(2) .list-name i {
            color: #fa7658;
          }

          &:nth-child(3) a i,
          &:nth-child(3) .list-name i {
            color: #2dce89;
          }

          &:nth-child(4) a i,
          &:nth-child(4) .list-name i {
            color: #11cdef;
          }

          &:nth-child(5) a i,
          &:nth-child(5) .list-name i {
            color: #f5365c;
          }

          &:nth-child(6) a i,
          &:nth-child(6) .list-name i {
            color: #36b05a;
          }

          &:nth-child(7) a i,
          &:nth-child(7) .list-name i {
            color: #ff7500;
          }

          &:nth-child(8) a i,
          &:nth-child(8) .list-name i {
            color: #2163ff;
          }

          &:nth-child(9) a i,
          &:nth-child(9) .list-name i {
            color: #8b26e4;
          }
        }
      }
    }
  }
}