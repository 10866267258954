.card-box {
  margin-bottom: 10px;

  svg.dashboard-card-icon-1 {
    background: linear-gradient(310deg, #85003c, #ff7fb8dd) !important;
  }

  svg.dashboard-card-icon-2 {
    background: linear-gradient(310deg, #25a3b9, #4ff0ffdd) !important;
  }

  svg.dashboard-card-icon-3 {
    background: linear-gradient(310deg, #1e9c65, #67feb3cc) !important;
  }

  svg.dashboard-card-icon-4 {
    background: linear-gradient(310deg, #7e20b1, #de90ffc1) !important;
  }

  svg.dashboard-card-icon-5 {
    background: linear-gradient(310deg, #d50000, #ff8d8dda) !important;
  }

  svg.dashboard-card-icon-6 {
    background: linear-gradient(310deg, #f5365c, #f56036) !important;
  }

  svg.dashboard-card-icon-7 {
    background: linear-gradient(310deg, #0d53b0, #54e5ff) !important;
  }

  svg.dashboard-card-icon-8 {
    background: linear-gradient(310deg, #a6910a, #fff789db) !important;
  }

  svg.dashboard-card-icon-9 {
    background: linear-gradient(310deg, #7a7a7a, #ffffff) !important;
  }
}

body.dark {
  .card-box {
    margin-bottom: 10px;

    svg.dashboard-card-icon-1 {
      background: linear-gradient(310deg, #8f174d, #ff7fb8dd) !important;
    }

    svg.dashboard-card-icon-2 {
      background: linear-gradient(310deg, #094c57, #3dccd9dd) !important;
    }

    svg.dashboard-card-icon-3 {
      background: linear-gradient(310deg, #0a5d39, #5df3a8cc) !important;
    }

    svg.dashboard-card-icon-4 {
      background: linear-gradient(310deg, #490c69, #de90ffc1) !important;
    }

    svg.dashboard-card-icon-5 {
      background: linear-gradient(310deg, #840000, #ff8d8dda) !important;
    }

    svg.dashboard-card-icon-6 {
      background: linear-gradient(310deg, #971f37, #ba4828) !important;
    }

    svg.dashboard-card-icon-7 {
      background: linear-gradient(310deg, #0d53b0, #54e5ff) !important;
    }

    svg.dashboard-card-icon-8 {
      background: linear-gradient(310deg, #625502, #fff789db) !important;
    }

    svg.dashboard-card-icon-9 {
      background: linear-gradient(310deg, #4d4a4a, #ffffff) !important;
    }
  }
}

.dashboard-card-1 {
  .dash-card {
    overflow: hidden;
    height: auto;
    padding: 0px;

    .up-card-1 {
      padding: 25px 25px 0;
    }

    .down-card-1 {
      align-content: space-around;
      padding: 18px 25px !important;
      align-content: space-around;
      margin: 11px 0 0;
      background: linear-gradient(130deg, #85003c, #ff82bad3) !important;

      .card-text {
        color: var(--white-color) !important;
      }

      .card-title {
        color: var(--white-color) !important;
      }
    }
  }
}