.passbook-view {
  i {
    font-size: 19px;
    transform: translateY(-3px);
    color: var(--text-color);
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      color: var(--primary-color);
    }
  }
}

.passbook-view-modal {
  .modal-dialog {
    box-shadow: 0 0 20px 1px #77777738;
  }

  .modal-header {
    color: var(--text-color);
    padding: 15px 25px;

    button {
      background-color: var(--white-color);
      box-shadow: 0px 0px 30px 0px #2b2b2b50;
      border-radius: 50%;
    }
  }

  .modal-body {
    padding: 8px 25px 20px !important;

    div {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
      font-size: 14px;

      span {
        color: var(--text-color);
        display: block;
        padding: 4px;
        text-transform: capitalize;

        &.tag-button {
          padding: 5px 15px;
          font-size: 11px;

          &.success {
            background-color: #E8FAF0 !important;
            color: #12A150 !important;
          }

          &.danger {
            background-color: #fee7e7 !important;
            color: #f31212 !important;
          }
        }
      }
    }
  }
}

body.dark {
  .passbook-view-modal {
    .modal-body {
      div {
        span {
          color: var(--text-color);

          &.tag-button {
            &.success {
              background-color: #094723 !important;
              color: #74DFA2 !important;
            }

            &.danger {
              background-color: #8b0f0f !important;
              color: #ffd7d7 !important;
            }
          }
        }
      }
    }
  }
}