.login-form {
  margin: 35px 0 10px 0;

  .form-group {
    input {
      border-radius: 20px !important;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    display: block;
    background: var(--secondary-400);
  }

  .title-b {
    margin-bottom: 20px;

    .title {
      font-size: $fs-20;
      color: var(--primary-color);
      font-weight: $fw-700;
      text-transform: uppercase;
    }

    p {
      font-size: $fs-12;
      color: var(--secondary-400);
      line-height: 1.45;
      letter-spacing: 0.5px;
      margin-top: 10px;
      font-style: italic;
    }

  }

  .login-button {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    letter-spacing: 0.8px;

    button {
      width: 100%;
    }

    .b-link {
      font-size: $fs-14;
      color: var(--text-color);
      text-transform: uppercase;
      font-weight: $fw-600;
      margin-top: 15px;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  @include media-max(md) {
    margin: 20px 0 15px 0;

    .title-b {
      margin-bottom: 10px;
    }
  }
}

.reset_expire {
  h5 {
    text-align: center;
    color: var(--text-color);
  }

  color: var(--text-color);
}