.modal-content {
  background-color: var(--boxes-color);
  border-radius: 10px;
  border: none;

  h3,
  h5,
  h6 {
    color: var(--heading-color);
    font-family: 'Montserrat', sans-serif;
  }

  .modal-header {
    border-bottom: 1px solid var(--secondary-100);
  }

  .modal-body {
    text-align: center;
    padding: 45px 20px;

    h5,
    h6 {
      font-weight: 300;
    }

    article {
      margin-block: 10px 20px;
    }
  }

  .modal-footer {
    border-top: 1px solid var(--secondary-100);
  }
}

.image-popup .carousel-inner {
  max-height: calc(100vh - 100px);
}

.modal-backdrop {
  background: #1C2430;
  opacity: 0.3 !important;
}

body.dark {
  .modal-content {
    h3 {
      color: var(--white-color) !important;
    }
  }
}