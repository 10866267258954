.top-bar {
  position: relative;
  top: -60px;
  // margin-bottom: 15px;

  @include media-max(md) {
    top: 0;
    margin-bottom: 15px !important;
  }

  .buttons {
    margin-left: 10px;

    .btn {
      border-radius: 5px;
      border: 1px solid transparent;
      padding: 8px 12px;
      color: var(--primary-color);
      border-color: var(--primary-color);
      background-color: var(--boxes-color);

      &:hover {
        background-color: var(--primary-color);
        color: var(--white-color) !important;
        border-color: var(--primary-color-light) !important;
      }
    }

    .btn+.btn {
      margin-left: 15px;
    }
  }
}

body.dark {
  .top-bar {
    .buttons {
      .btn {
        color: var(--secondary-100);
        border-color: var(--primary-color);
        background-color: var(--primary-color);

        &:hover {
          background-color: var(--white-color);
          color: var(--primary-color) !important;
          border-color: var(--white-color) !important;
        }
      }
    }
  }
}