.header {
  padding: 5px 12px 5px 16px;
  // border-bottom: 1px solid #f2f4f7;
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding 0.3s ease-in-out;

  .logo {
    transition: all 0.3s;
    display: flex;
    align-items: center;

    .logo-text {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.8px;
      padding-top: 15px;
      color: var(--white-color);
    }

    img {
      &.logoIcon {
        width: 120px;
        height: auto;
      }

      &.textLogo {
        width: 250px;
        height: auto;
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 5px;

    .dropdown {
      .dropdown-menu.up-arrow {
        margin-top: 5px;
      }
    }

    .btn {
      border: 1px solid transparent;
      font-size: $fs-18;
      font-weight: 700;
      border-radius: 8px;
      padding: 0;
      background-color: unset;
      border-radius: 4px;
      // width: 50px;
      height: 35px;
      text-transform: capitalize;
      color: var(--white-color);
      display: flex;
      justify-content: space-between;
      place-items: center;
      border: unset !important;

      &:hover {
        box-shadow: unset !important;
        border-color: unset;
        background-color: unset;
        color: var(--white-color) !important;
      }

      img {
        width: 32px;
        height: 32px;
        border: 2px solid var(--text-hover);
        border-radius: 99px;
      }

      .svg-content {
        width: 32px;
        height: 32px;
        border: 2px solid var(--white-color);
        border-radius: 99px;
        padding: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
      }

      // &.user-btn {
      //   // margin-right: 11px;
      // }
    }

    .user-name {
      font-weight: 700;
      font-size: 15px;
      color: var(--white-color);
      letter-spacing: 0.8px;
      transform: translateY(0px);
      font-family: $primary-font;
    }
  }

  .dropdown-toggle {
    &:hover {
      border-color: var(--primary-color);

      .img svg path {
        fill: var(--white-color);
      }
    }

    &:after {
      display: none;
    }

    .img svg path {
      fill: var(--white-color);
    }
  }

  @include media-max(md) {
    padding: 8px 12px;
  }
}

body.dark {
  .header {
    background-color: var(--primary-color);

    &-right {
      .btn {
        color: var(--text-color);

        &:hover {
          color: var(--text-color) !important;
        }
      }

      .user-name {
        color: var(--text-color);
      }
    }

    .header-right {
      .btn:hover {
        box-shadow: unset !important;
        border-color: unset;
        background-color: unset;
        color: var(--white-color) !important;
      }

      .theme-setting {
        color: var(--white-color);
      }
    }

    .dropdown-toggle {
      &:hover {
        .img svg path {
          fill: var(--text-color);
        }
      }

      .img svg path {
        fill: var(--text-color);
      }
    }
  }
}

// -------- dark and light toggle button ---------

.header {
  .header-right {
    .theme-setting {
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: end;
      font-size: 14px;
      padding: 13px 20px;
      padding-top: 5px !important;
      color: var(--primary-color);
    }
  }
}


body.dark {
  .header {
    .header-right {
      .slider {
        background-color: #7f1190;
      }

      input {
        &+.slider:before {
          transform: translatex(32px);
        }

        &+.slider.round:before {
          font-size: 14px;
          background: transparent;
          // content: '🌖';
          content: '🌙';
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}