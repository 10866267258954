.inputtypefile {
  padding: 7px 10px 7px 12px;
  position: relative;
  background-color: var(--input-background-color);
  border-radius: 4px;
  // border: 1px solid #000;


  .inputMSG {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      all: unset;
      font-size: 11px;
      color: var(--white-color);
      background-color: var(--input-select-background-color);
      padding: 5px 10px;
      border-radius: 5px;
      font-family: 'helvetica-regular';
      font-weight: 400;
    }
  }

  input {
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    position: absolute;

    &:disabled {
      display: none;
    }
  }
}

.fileinput {
  margin: 0 0 15px;
}

.document-preview-group {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 15px;
  margin: 15px 0 0;
  transition: 0.3s ease-in-out;

  .document-preview {
    border: 1px solid var(--text-color);
    width: 150px;
    padding: 15px;

    img {
      all: unset;
      width: 100%;
    }
  }
}